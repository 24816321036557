import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import { Helmet } from "react-helmet";
import { Heading, Box, Flex, Button } from "@chakra-ui/core";
import Container from "../components/Container";
import Banner from "../components/Banner";
import FormattedContent from "../components/FormattedContent";

export const SimplePageTemplate = ({
  title,
  content,
  heading,
  subheading,
  image,
  callToAction,
  contentComponent
}) => {
  const PageContent = contentComponent || Content;

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Container>
        <Box h={["300px", "280px", "250px", "200px"]}>
          <Container position="relative">
            <Banner.Container>
              <Banner.Image image={image} />
            </Banner.Container>
          </Container>
        </Box>
        <Box mb={[50, 50, 50, 75]}>
          <Heading as="h1" size="2xl" color="#4a93f7">
            {heading}
          </Heading>
          <Heading as="h2" size="lg" color="#5c626e" fontWeight="normal">
            {subheading}
          </Heading>
        </Box>
        <Flex flexDirection={["column", "column", "row"]}>
          <Box flexGrow={1} mb={[50, 50, 0]}>
            <FormattedContent>
              <PageContent content={content} />
            </FormattedContent>
          </Box>
          {callToAction && (
            <Box w={["100%", "100%", "420px"]} pl={[0, 0, 80]} flexShrink={0}>
              <Button
                as="a"
                rightIcon="arrow-forward"
                size="lg"
                w="100%"
                href={callToAction.url}
              >
                {callToAction.text}
              </Button>
            </Box>
          )}
        </Flex>
      </Container>
    </>
  );
};

SimplePageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  image: PropTypes.object,
  callToAction: PropTypes.object
};

const SimplePage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <SimplePageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        heading={post.frontmatter.heading}
        subheading={post.frontmatter.subheading}
        image={post.frontmatter.image}
        callToAction={post.frontmatter.callToAction}
        content={post.html}
      />
    </Layout>
  );
};

SimplePage.propTypes = {
  data: PropTypes.object.isRequired
};

export default SimplePage;

export const SimplePageQuery = graphql`
  query SimplePage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        heading
        subheading
        image {
          childImageSharp {
            fluid(maxWidth: 600, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        callToAction {
          url
          text
        }
      }
    }
  }
`;
